import * as React from "react"
import { Col, Row, Container } from "react-bootstrap"
//Components Import
import CallToAction from "../components/button/index"
import ComingSoonHeader from "../components/header_comingsoon"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as Styles from "../style/home.module.scss"

import Contactus from '../components/Contactus'

const ContactPage = () => {

  return (
    <Layout>
      <Seo title="Contact" />
      <div id="Home" className={Styles.banner_comingsoon}>
        <ComingSoonHeader />
        
        <hr style={{ border: "#fff", height: "0.005em", color: "#86EEE6", backgroundColor: "#86EEE6" }} />
        <Container fluid >
          <Row>
          <Col md={1}></Col>
            <Col md={5} className={`${Styles.paddingTop150}`}>
              <div>
                <span className={` ${Styles.bannerHeading_comingSoon}`}>
                  We are <br />Launching Soon..
                </span>
                <div className={`${Styles.TextJustifyBoldSmall}`}>
                  India's first online platform for commerce skilling & job enhancement will reach you soon!
                </div>

              </div>
            </Col>
            <Col md={6} className={Styles.comingsoon}></Col>
          </Row>

        </Container>
      </div>
      <div>
        <Container fluid >

          <Row>
            <Col md={2}></Col>
            <Col md={8} >
              <Contactus></Contactus>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
    </Layout>

  )
}
export default ContactPage
